import * as DF from 'date-fns';
import LatLon from 'geodesy/latlon-spherical.js';
import Util from './Util';

/**
 * Formats a given number.
 *
 * @param {Number|String} num
 * @param {Object} [userOpts] User configuration
 * @returns {String}
 */
function number (num, userOpts) {
    if (num === undefined) return '';
    const defaults = {
        decimals: 2,
        prefix: '',
        suffix: '',
        separator: {
            decimal: '.',
            thousand: ','
        }
    };

    num = num || 0;
    userOpts = userOpts || {};
    const opts = Util.extend(defaults, userOpts);

    // Cast the string to a number.
    if (typeof num === 'string') {
        num *= 1;
    }

    if (!isFinite(num)) {
        return num.toString();
    }
    num = num.toFixed(opts.decimals);

    // Replace the dot.
    if (opts.separator.decimal !== '.') num = num.replace(/\./, opts.separator.decimal);

    // Set the thousand separator.
    const numParts = num.split(opts.separator.decimal);
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, opts.separator.thousand);
    num = opts.prefix + numParts.join(opts.separator.decimal) + opts.suffix;

    return num;
}

/**
 * Format a number to 3 decimals.
 *
 * @param {*} value Cell value.
 * @returns {String}
 */
function number3 (value) {
    if (typeof value !== 'number') return value;
    return number(value, {
        decimals: 3
    });
}

/**
 * Format a number to 4 decimals.
 *
 * @param {*} value Cell value.
 * @returns {String}
 */
function number4 (value) {
    if (typeof value !== 'number') return value;
    return number(value, {
        decimals: 4
    });
}

/**
 * Format a number to 5 decimals.
 *
 * @param {*} value Cell value.
 * @returns {String}
 */
function number5 (value) {
    if (typeof value !== 'number') return value;
    return number(value, {
        decimals: 5
    });
}

/**
 * Format a number to 6 decimals.
 *
 * @param {*} value Cell value.
 * @returns {String}
 */
function number6 (value) {
    if (typeof value !== 'number') return value;
    return number(value, {
        decimals: 6
    });
}

/**
 * Format number as currency.
 *
 * @param {*} value Cell value.
 * @returns {String}
 */
function currency (value) {
    const vue = window.srvyVueInst;
    if (!vue) return '';
    const locale = vue.$_FORMAT_LOCALE;

    if (locale === 'en-ZA') {
        return number(value, {
            decimals: 2,
            prefix: 'R'
        });
    }
    return vue.$n(value, 'currency', locale);
}

/**
 * Formatter number as currency without cents.
 *
 * @param {*} value Cell value.
 * @returns {String}
 */
function currencyInt (value) {
    return number(value, {
        decimals: 0,
        prefix: 'R'
    });
}

function dateLong (value) {
    const vue = window.srvyVueInst;
    if (!vue) return '';
    const locale = vue.$_FORMAT_LOCALE;

    if (typeof value === 'string' &&
        (value.length === 24 || value.length === 19) &&
        value[13] === ':') value = new Date(value);
    if (!DF.isDate(value)) return value;
    return vue.$d(value, 'long', locale);
}

/**
 * Element UI table formatter for short dates.
 *
 * @param {*} rec Data record.
 * @param {*} col Column definition.
 * @param {*} value Cell value.
 * @returns {String}
 */
function dateShort (value) {
    const vue = window.srvyVueInst;
    if (!vue) return '';
    const locale = vue.$_FORMAT_LOCALE;

    if (typeof value === 'string' &&
        (value.length === 24 || value.length === 19) &&
        value[13] === ':') value = new Date(value);
    if (!DF.isDate(value)) return value;
    /* if (locale === 'en-ZA') {
        return DF.format(value, 'dd MMMM yyyy');
    } */
    return vue.$d(value, 'short', locale);
}

/**
 * Element UI table formatter for date & time.
 *
 * @param {*} rec Data record.
 * @param {*} col Column definition.
 * @param {*} value Cell value.
 * @returns {String}
 */
function dateTime (value) {
    const vue = window.srvyVueInst;
    if (!vue) return '';
    const locale = vue.$_FORMAT_LOCALE;

    if (typeof value === 'string' &&
        (value.length === 24 || value.length === 19) &&
        value[13] === ':') value = new Date(value);
    if (!DF.isDate(value)) return value;
    /* if (locale === 'en-ZA') {
        return DF.format(value, 'dd MMMM yyyy');
    } */
    // return DF.format(value, 'yyyy-MM-dd');
    return vue.$d(value, 'long+time', locale);
}

/**
 * Element UI table formatter for date & time.
 *
 * @param {*} value Cell value.
 * @returns {Date}
 */
function dateTimeCustom (value) {
    const vue = window.srvyVueInst;
    if (!vue) return '';

    const dateOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    };

    return new Date(value).toLocaleDateString('en-ZA', dateOptions);
}

/**
 * Element UI table formatter for date & time.
 *
 * @param {*} rec Data record.
 * @param {*} col Column definition.
 * @param {*} value Cell value.
 * @returns {String}
 */
function dateShortTime (value) {
    if (typeof value === 'string' &&
        (value.length === 24 || value.length === 19) &&
        value[13] === ':') {
        value = new Date(value);
    }
    const shortTime = value.getHours() + ':' + ('00' + value.getMinutes()).slice(-2);
    return shortTime;
}

function dateYMD (dt) {
    if (Object.prototype.toString.call(dt) !== '[object Date]') return dt;
    // return `${dt.getFullYear()}${((dt.getMonth() + 1) + '').padStart(2, '0')}${dt.getDate()}`;
    return dt.toISOString().substr(0, 10).replace(/-/, 'g');
}

function dateYMDDash (dt) {
    if (Object.prototype.toString.call(dt) !== '[object Date]') return dt;
    return dt.toISOString().substr(0, 10);
}

/**
 * Format number as integer.
 *
 * @param {*} value Cell value.
 * @returns {String}
 */
function int (value) {
    const vue = window.srvyVueInst;
    if (!vue) return '';
    const locale = vue.$_FORMAT_LOCALE;

    if (typeof value !== 'number') return value;
    if (locale === 'en-ZA') {
        return number(value, {
            decimals: 0
        });
    }
    return vue.$n(value, 'int', locale);
}

/**
 * Element UI table formatter for integers. If zero return a -
 *
 * @param {*} rec Data record.
 * @param {*} col Column definition.
 * @param {*} value Cell value.
 * @returns {String}
 */
function intZeroDash (value) {
    if (!value) return '-';
    return int(value);
}

/**
 * Element UI table formatter for integers. If zero return a blank string.
 *
 * @param {*} rec Data record.
 * @param {*} col Column definition.
 * @param {*} value Cell value.
 * @returns {String}
 */
function intZeroEmpty (value) {
    if (!value) return '';
    return int(value);
}

/**
 * Format the number of bytes into the appropriate human-readable unit.
 *
 * @param {Number} bytes Number of bytes.
 * @param {Number} decimals Decimal places. Not enforced e.g. 7.20 will yield 7.2, but 7.2334 will yield 7.23
 * @returns String representation in bytes unit.
 */
function byteSize (bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const dm = decimals < 0 ? 0 : decimals;
    const e = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / Math.pow(1024, e)).toFixed(dm))} ${' KMGTP'.charAt(e)}B`; // parseFloat to strip trailing 0's.
}

/**
 * Element UI table formatter for integer kilometers.
 *
 * @param {*} rec Data record.
 * @param {*} col Column definition.
 * @param {*} value Cell value.
 * @returns {String}
 */
function km (value) {
    return number(value, {
        decimals: 0,
        suffix: ' km'
    });
}

function time (value) {
    /* const i = parseInt(value, 10);
    const frac = value - i;
    const mins = `${60 * frac}00`.substr(0, 2);
    return `${i}:${mins}`; */

    if (typeof value !== 'number') return '0:00';
    value += ''; // Make a string.
    if (value.indexOf('.') > -1) { // Float.
        value = value.split('.');
        if ((!value[0] || value[0] === '0') && (!value[1] || value[1] === '0')) {
            value = [];
        }
        else {
            if (!value[0]) value[0] = '0';
            if (!value[1] || value[1] === '0') {
                value[1] = '00';
            }
            else {
                let m = Math.round(60 * parseFloat(`0.${value[1]}`));
                if (m < 1 && (!value[0] || value[0] === '0')) {
                    value = [];
                }
                else {
                    if (m < 10) m = `0${m}`;
                    value[1] = `${m}00`.substr(0, 2);
                }
            }
        }
    }
    else { // Integer.
        value = (!value || value === '0') ? [] : [value, '00'];
    }
    return value.length ? value.join(':') : '0:00';
}
function hoursMinutes (dt) {
    const now = dt;
    return (now.getHours() + ':' + ((now.getMinutes() < 10) ? ('0' + now.getMinutes()) : (now.getMinutes())));
}
/**
 * Element UI table formatter for integer percentage.
 *
 * @param {*} rec Data record.
 * @param {*} col Column definition.
 * @param {*} value Cell value.
 * @returns {String}
 */
function percent (value) {
    return number(value, {
        decimals: 2,
        suffix: '%',
    });
}

/**
 * Element UI table formatter for integer percentage.
 *
 * @param {*} rec Data record.
 * @param {*} col Column definition.
 * @param {*} value Cell value.
 * @returns {String}
 */
function percentInt (value) {
    return number(value, {
        decimals: 0,
        suffix: '%',
    });
}

function gps (lat, lon, format) {
    if (['d', 'dm', 'dms', 'n', 'dmst'].indexOf(format) === -1) format = 'dms';
    if (format === 'dmst') return dmsLatLong(lat, lon);
    const p = new LatLon(lat, lon);
    return p.toString(format);
}

/**
 * Returns the coordinate conversion string in DD to DMS.
 * Mongo stores it as long then lat as in [long, lat].
 *
 * @param  {} lat - Latitude point.
 * @param  {} lon - Longitude point.
 */
function dmsLatLong (lat, lon) {
    lat = parseFloat(lat);
    lon = parseFloat(lon);

    // Call getDms(value) for the coordinate in DMS format.
    // const lonDms = `${lon >= 0 ? 'E' : 'W'}${getDms(lon)}`;
    const lonDms = `${getDms(lon)} ${lon >= 0 ? 'E' : 'W'}`;
    // const latDms = `${lat >= 0 ? 'N' : 'S'}${getDms(lat)}`;
    const latDms = `${getDms(lat)} ${lat >= 0 ? 'N' : 'S'}`;

    return `${latDms}, ${lonDms}`;
}

/**
 * Used by `prettyLatLong`
 *
 * @param {Number} val Coordinate value.
 */
function getDms (val) {
    const result = [];
    val = Math.abs(val);

    const valDeg = Math.floor(val);
    result.push(`${valDeg}°`.padStart(3, '0')); // º

    const valMin = Math.floor((val - valDeg) * 60);
    result.push(`${valMin}'`.padStart(3, '0'));

    // const valSec = Math.round((val - valDeg - valMin / 60) * 3600 * 1000) / 1000;
    const valSec = Math.round(Math.round((val - valDeg - valMin / 60) * 3600 * 1000) / 1000);
    result.push(`${valSec}"`.padStart(3, '0'));

    return result.join('');
}

/**
 * Element UI table formatter for boolean to Yes or No.
 *
 * @param {*} rec Data record.
 * @param {*} col Column definition.
 * @param {*} value Cell value.
 * @returns {String}
 */
function yesNo (value) {
    return value ? 'Yes' : 'No';
}

export default {
    number,
    number3,
    number4,
    number5,
    number6,
    currency,
    currencyInt,
    int,
    dateLong,
    dateShort,
    dateTime,
    dateTimeCustom,
    dateShortTime,
    dateYMD,
    dateYMDDash,
    intZeroDash,
    intZeroEmpty,
    byteSize,
    km,
    time,
    hoursMinutes,
    percent,
    percentInt,
    gps,
    dmsLatLong,
    yesNo
};
